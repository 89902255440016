import { useLocation } from '@reach/router';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { usePageContext } from '../../pageContext';
import LanguageSwitcher from '../LanguageSwitcher';
import Link from '../Link';

function MenuBarSection({ data, lang }) {

    const { pathname } = useLocation();

    const languageData = useTranslation()[1].store.data;

    const echo = (expression) => {
        return languageData[lang]['menuSection'][expression];
    }

    return (
        <div className="art-menu-bar">

            {/* <!-- menu bar frame --> */}
            <div className="art-menu-bar-frame">

                {/* <!-- menu bar header --> */}
                <div className="art-menu-bar-header">
                    {/* <!-- menu bar button --> */}
                    <a className="art-menu-bar-btn" href="#">
                        {/* <!-- icon --> */}
                        <span></span>
                    </a>
                    {/* <!-- menu bar button end --> */}
                </div>
                {/* <!-- menu bar header end --> */}

                {/* <!-- current page title --> */}
                <div className="art-current-page"></div>
                {/* <!-- current page title end --> */}

                {/* <!-- scroll frame --> */}
                <div className="art-scroll-frame">

                    {/* <!-- menu --> */}
                    <nav id="swupMenu">
                        {/* <!-- menu list --> */}
                        <ul className="main-menu">

                            {/* <!-- menu item --> */}
                            <li className={["menu-item", (['/', '/fr', '/en', '/fr/', '/en/'].indexOf(pathname) != -1) ? "current-menu-item" : ''].join(" ")}>
                                <Link to={'/'}>{echo('home')}</Link>
                            </li>
                            {/* <!-- end menu item --> */}

                            {/* <!-- menu item --> */}
                            <li className={["menu-item", (pathname.indexOf('/mes-travaux') == 3) ? 'current-menu-item' : ''].join(" ")}>
                                <Link to={'/mes-travaux'}>{echo('works')}</Link>
                            </li>
                            {/* <!-- menu item --> */}

                            {/* <!-- menu item --> */}
                            <li className={["menu-item", (pathname.indexOf('/mon-parcours') == 3) ? 'current-menu-item' : ''].join(" ")}>
                                <Link to={'/mon-parcours'}>{echo('history')}</Link>
                            </li>
                            {/* <!-- menu item --> */}

                            {/* <!-- menu item --> */}
                            <li className={["menu-item", (pathname.indexOf('/articles') == 3) ? 'current-menu-item' : ''].join(" ")}>
                                <Link to={'/articles'}>Blog</Link>
                            </li>
                            {/* <!-- menu item --> */}

                            {/* <!-- menu item --> */}
                            <li className={["menu-item", (pathname.indexOf('/contact') == 3) ? 'current-menu-item' : ''].join(" ")}>
                                <Link to={'/contactez-moi'}>Contact</Link>
                            </li>
                            {/* <!-- end menu item --> */}

                        </ul>
                        {/* <!-- menu list end --> */}
                    </nav>
                    {/* <!-- menu end --> */}

                    <LanguageSwitcher />

                </div>
                {/* <!-- scroll frame end --> */}

            </div>
            {/* <!-- menu bar frame --> */}

        </div>
    )
}

export default MenuBarSection