/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router";
import { usePageContext } from "../pageContext";
import { I18nextProvider, useTranslation } from 'react-i18next';

function Seo({ description, meta, title, image }) {

  const { site } = useStaticQuery(
    graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
             siteURL
             supportedLanguages
           }
         }
       }
     `
  );

  const { lang, originalPath } = usePageContext();

  const location = useLocation();
  const { t } = useTranslation();

  const metaDescription = description || t('siteMetadata.description');
  const defaultTitle = site.siteMetadata?.title
   const host = site.siteMetadata?.siteURL;
  // const host = location.origin + '/';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={title ? `%s | ${t('siteMetadata.title')}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: "hanniel,hanniel tsasse, developpeur web, developpeur mobile, developpeur web et mobile, géolocalisation des pharmacies, création de site web, application mobile",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: host + location.pathname.substr(1, location.pathname.length),
        },
        {
          property: 'og:image',
          content: host + (image ? image : 'assets/img/face-1.jpg')
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:url`,
          content: host + location.pathname.substr(1, location.pathname.length),
        },
        {
          property: 'twitter:image',
          content: host + (image ? image : 'assets/img/face-1.jpg')
        },
      ].concat(meta)}

      link={[
        {
          rel: 'canonical',
          href: `${host}${lang}${originalPath}`,
        },
        {
          rel: 'alternate',
          hrefLang: 'x-default',
          href: `${host}${originalPath}`,
        },
        ...site.siteMetadata.supportedLanguages.map(supportedLang => ({
          rel: 'alternate',
          hrefLang: supportedLang,
          href: `${host}${supportedLang}${originalPath}`,
        })),
      ]}
    />
  )
}

Seo.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
