import React, { Children, Fragment, useEffect, useState } from 'react'
import InfoBar from './InfoBar'

import FooterSection from './sections/FooterSection'
import MenuBarSection from './sections/MenuBarSection'

import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import { useTranslation } from 'react-i18next';
import { usePageContext } from '../pageContext';

deckDeckGoHighlightElement();

function Layout({ children, data, lang }) {
    // const [already, setAlready] = useState(false);

    // useEffect(() => {

    //     setTimeout(() => {
    //         setAlready(true);
    //     }, 1400);

    //     return () => {

    //     }
    // }, []);

    return (
        <Fragment>
            <div className="art-app">

                {/* <!-- mobile top bar --> */}
                <div className="art-mobile-top-bar"></div>

                {/* <!-- app wrapper --> */}
                <div className="art-app-wrapper">

                    {/* <!-- app container end --> */}
                    <div className="art-app-container">

                        <InfoBar lang={lang} />

                        {/* <!-- content --> */}
                        <div className="art-content">
                            {/* <!-- curtain --> */}
                            <div className="art-curtain"></div>

                            {/* <!-- top background --> */}
                            <div className="art-top-bg" style={{ backgroundImage: "url(/assets/img/bg.jpg)" }}>
                                {/* <!-- overlay --> */}
                                <div className="art-top-bg-overlay"></div>
                                {/* <!-- overlay end --> */}
                            </div>
                            {/* <!-- top background end --> */}

                            {/* <!-- swup container --> */}
                            <div className="transition-fade" id="swup">

                                {/* <!-- scroll frame --> */}
                                <div id="scrollbar" className="art-scroll-frame">
                                    {children}
                                    <FooterSection lang={lang} />
                                </div>
                                {/* <!-- scroll frame end --> */}

                            </div>
                            {/* <!-- swup container end --> */}
                        </div>
                        {/* <!-- content end --> */}

                        <MenuBarSection data={data} lang={lang} />

                    </div>
                    {/* <!-- app container end --> */}

                </div>
                {/* <!-- app wrapper end --> */}

                {/* <!-- preloader --> */}
                <div className="art-preloader">
                    <div className="art-preloader-content">
                        <h4>Hanniel TSASSE</h4>
                        <div id="preloader" className="art-preloader-load"></div>
                    </div>
                </div>

                {/* <!-- preloader end --> */}

            </div>
            {/* <div id="swupMenu"></div> */}

            {/* <div className="loader loader-fade-out">
                <span>Hanniel TSASSE</span>
            </div> */}
        </Fragment>
    )
}

export default Layout
