import React from 'react'
import SkillProgressItem from './elements/SkillProgressItem'

import photo from './../images/face-1.jpg';
import { useTranslation } from 'react-i18next';
import { usePageContext } from '../pageContext';

function InfoBar({ lang }) {

    const languageData = useTranslation()[1].store.data;

    // const { lang } = usePageContext();

    const echo = (expression) => {
        return languageData[lang]['infoBar'][expression];
    }

    return (
        <div className="art-info-bar">

            {/* <!-- menu bar frame --> */}
            <div className="art-info-bar-frame">

                {/* <!-- info bar header --> */}
                <div className="art-info-bar-header">
                    {/* <!-- info bar button --> */}
                    <a className="art-info-bar-btn" href="#">
                        {/* <!-- icon --> */}
                        <i className="fas fa-ellipsis-v"></i>
                    </a>
                    {/* <!-- info bar button end --> */}
                </div>
                {/* <!-- info bar header end --> */}

                {/* <!-- info bar header --> */}
                <div className="art-header">
                    {/* <!-- avatar --> */}
                    <div className="art-avatar">
                        <a title="Hanniel TSASSE Avatar" data-fancybox="avatar" href='/assets/images/face-1.jpg' className="art-avatar-curtain">
                            <img src={photo} alt="avatar" />
                            <i className="fas fa-expand"></i>
                        </a>
                        {/* <!-- available --> */}
                        <div className="art-lamp-light">
                            {/* <!-- add class 'art-not-available' if not available--> */}
                            <div className="art-available-lamp"></div>
                        </div>
                    </div>
                    {/* <!-- avatar end --> */}
                    {/* <!-- name --> */}
                    <h5 className="art-name mb-10">Hanniel TSASSE</h5>
                    {/* <!-- post --> */}
                    <div className="art-sm-text">{echo('web.mobile')}<br />{echo('react')}</div>
                </div>
                {/* <!-- info bar header end --> */}

                {/* <!-- scroll frame --> */}
                <div id="scrollbar2" className="art-scroll-frame">

                    {/* <!-- info bar about --> */}
                    <div className="art-table p-15-15">
                        {/* <!-- about text --> */}
                        <ul>
                            {/* <!-- country --> */}
                            <li>
                                <h6>{echo('residence')} : </h6><span>Cameroun</span>
                            </li>
                            {/* <!-- city --> */}
                            <li>
                                <h6>{echo('city')} : </h6><span>Bafoussam</span>
                            </li>
                            {/* <!-- age --> */}
                            <li>
                                <h6>{echo('years')} : </h6><span>19</span>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- info bar about end --> */}

                    {/* <!-- divider --> */}
                    <div className="art-ls-divider"></div>

                    {/* <!-- language skills --> */}
                    <div className="art-lang-skills p-30-15">

                        {/* <!-- skill --> */}
                        <div className="art-lang-skills-item">
                            <div data-value="98" className="art-cirkle-progress circleprog"></div>
                            {/* <!-- title --> */}
                            <h6>Français</h6>
                        </div>
                        {/* <!-- skill end --> */}

                        {/* <!-- skill --> */}
                        <div className="art-lang-skills-item">
                            <div data-value="70" className="art-cirkle-progress circleprog"></div>
                            {/* <!-- title --> */}
                            <h6>English</h6>
                        </div>
                        {/* <!-- skill end --> */}

                    </div>
                    {/* <!-- language skills end --> */}

                    {/* <!-- divider --> */}
                    <div className="art-ls-divider"></div>

                    {/* <!-- hard skills --> */}
                    <div className="art-hard-skills p-30-15">

                        <SkillProgressItem title="html" value={90} />
                        <SkillProgressItem title="CSS" value={80} />
                        <SkillProgressItem title="Js" value={85} />

                        <SkillProgressItem title="ReactJS" value={75} />
                        <SkillProgressItem title="React Native" value={75} />
                        <SkillProgressItem title="Gatsby" value={65} />

                        <SkillProgressItem title="Flutter" value={50} />
                        <SkillProgressItem title="Angular" value={50} />
                        <SkillProgressItem title="IONIC" value={50} />

                        <SkillProgressItem title="Firebase" value={85} />
                        <SkillProgressItem title="PHP" value={80} />
                        <SkillProgressItem title="NodeJS" value={65} />

                        <SkillProgressItem title="Wordpress" value={70} />

                        <SkillProgressItem title="Swing" value={60} />

                    </div>
                    {/* <!-- hard skills end --> */}

                    {/* <!-- divider --> */}
                    <div className="art-ls-divider"></div>

                    {/* <!-- knowledge list --> */}
                    <ul className="art-knowledge-list p-15-0">
                        <li>Bootstrap, jQuery, Materialize</li>
                        <li>Redux</li>
                        <li>MongoDB, Firebase, Express</li>
                        <li>{echo('connaissances.git')}</li>
                    </ul>
                    {/* <!-- knowledge list end --> */}

                    {/* <!-- divider --> */}
                    <div className="art-ls-divider"></div>

                    {/* <!-- links frame --> */}
                    <div className="art-links-frame p-15-15">

                        {/* <!-- download cv button --> */}
                        <a title='Télécharger le CV de Hanniel TSASSE' href="files/cv.pdf" className="art-link" download>{echo('download')}<i className="fas fa-download"></i></a>

                    </div>
                    {/* <!-- links frame end --> */}

                </div>
                {/* <!-- scroll frame end --> */}

                {/* <!-- sidebar social --> */}
                <div className="art-ls-social">
                    {/* <!-- social link --> */}
                    <a title="Hanniel TSASSE Linkedin" href="https://www.linkedin.com/in/hanniel-tsasse-9b5693194" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                    {/* <!-- social link --> */}
                    <a title="Hanniel TSASSE Facebook" href="https://facebook.com/hanniel.tsasse" target="_blank"><i className="fab fa-facebook"></i></a>
                    {/* <!-- social link --> */}
                    <a href="#" target="_blank"><i className="fab fa-behance"></i></a>
                    {/* <!-- social link --> */}
                    <a title="Hanniel TSASSE GitLab" href="https://gitlab.com/tatsabonghanniel" target="_blank"><i className="fab fa-github"></i></a>
                    {/* <!-- social link --> */}
                    <a title="Hanniel TSASSE Twitter" href="https://twitter.com/tsassehanniel" target="_blank"><i className="fab fa-twitter"></i></a>
                </div>
                {/* <!-- sidebar social end --> */}

            </div>
            {/* <!-- menu bar frame end --> */}

        </div>
    )
}

export default InfoBar
