import React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import { usePageContext } from '../pageContext';
import { useLocation } from '@reach/router';

const Link = ({ to, ref, children, ...rest }) => {
  const { lang } = usePageContext();
  const { origin } = useLocation();

  // return <GatsbyLink {...rest} to={`/${lang}${to}`} />;
  return <a {...rest}
    href={`/${lang}${to}`}
    onClick={(e) => {
      e.preventDefault();
      window.location = `${origin}/${lang}${to}`;
    }}
  >{children}</a>;
};

export default Link;