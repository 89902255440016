import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { usePageContext } from '../pageContext';
// import Link from './Link';
import { useLocation } from '@reach/router';


const LanguageSwitcher = () => {
  const { originalPath, lang } = usePageContext();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            supportedLanguages
            siteURL
          }
        }
      }
    `
  );

  console.log(useLocation());

  const { origin } = useLocation();

  return (
    <ul className="art-language-change">
      {site.siteMetadata.supportedLanguages.map((supportedLang, index) => (
        <li key={index} className={lang == supportedLang ? 'art-active-lang' : ''}>
          <a
            onClick={(e) => {
              e.preventDefault();
              window.location = `${origin}/${supportedLang}${originalPath}`;
            }}
            title={`Change language to ${supportedLang}`}
            href={`/${supportedLang}${originalPath}`}
          >
            {supportedLang.toUpperCase()}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default LanguageSwitcher;