import React, { Fragment } from 'react'

function FooterSection() {
    return (
        <Fragment>
            {/* <!-- container --> */}
            <div className="container-fluid">
                {/* <!-- row --> */}
                <div className="row">

                    {/* <!-- col --> */}
                    <div className="col-6 col-lg-3">
                        {/* <!-- brand --> */}
                        <img className="art-brand" src={'/assets/img/brands/1.png'} alt="brand" />
                    </div>
                    {/* <!-- col end --> */}

                </div>
                {/* <!-- row end --> */}

            </div>
            {/* <!-- container end --> */}

            {/* <!-- container --> */}
            <div className="container-fluid">

                {/* <!-- footer --> */}
                <footer>
                    {/* <!-- copyright --> */}
                    <div>© 2020 3D && WEBMASTER 3.0</div>

                    {/* <!-- author ( Please! Do not delete it. You are awesome! :) --> */}
                    <div>Contact author:&#160; <a title="Mail to Hanniel TSASSE" href="mailto:contact@webmaster.kouelab.com" target="_blank">Hanniel TSASSE</a></div>
                </footer>
                {/* <!-- footer end --> */}

            </div>
            {/* <!-- container end --> */}
        </Fragment>
    )
}

export default FooterSection
