import React from 'react'

function SkillProgressItem({ title, value }) {
    return (
        <div className="art-hard-skills-item">
            <div className="art-skill-heading">
                {/* <!-- title --> */}
                <h6>{title}</h6>
            </div>
            {/* <!-- progressbar frame --> */}
            <div className="art-line-progress">
                {/* <!-- progressbar --> */}
                <div className="lineprog" data-value={value}></div>
            </div>
            {/* <!-- progressbar frame end --> */}
        </div>
    )
}

export default SkillProgressItem
